
import { Vue, Component, Prop } from "vue-property-decorator";

interface AuthValue {
  user: any;
  loggedIn: boolean;
  logout(): any;
}
@Component
export default class Menu extends Vue {
  @Prop({ default: {} }) auth!: AuthValue;
  @Prop({ default: true }) readonly localeSwitcher!: boolean;

  get permissions(): any {
    return this.$store.state.permissions || {};
  }

  get canManageUser(): boolean {
    return !!this.permissions.canManageUsers;
  }

  get userRealName(): string {
    const name: string = this.auth.user.name;
    if (name.includes("@")) {
      return this.$t("navbar.dashboard_user") as string;
    } else {
      return name;
    }
  }

  logout(): void {
    this.auth.logout();
  }

  setLocale(locale: string): void {
    this.$store.dispatch("setUserLocale", locale);
    this.$i18n.setLocale(locale);
  }
}
