
import { Vue, Component } from "vue-property-decorator";

import BtnClose from "~/components/common/BtnClose.vue";
import Footer from "@/components/common/Footer.vue";
import Navbar from "@/components/common/Navbar.vue";
import ManagementSidebar from "@/components/common/ManagementSidebar.vue";

@Component({
  components: { BtnClose, Navbar, ManagementSidebar, Footer },
  middleware: ["authenticated", "userdata"],
})
export default class ManagementLayout extends Vue {
  created(): void {
    this.$store.dispatch("loadUserData").then(() => {
      this.switchLocale();
    });
  }

  switchLocale(): void {
    const currentLocale = this.$i18n.locale;
    const targetLocale = this.$store.state.locale;
    if (this.$store.state.userDataLoaded && currentLocale !== targetLocale) {
      // we need to avoid the persons route because it redirects to the default country
      this.$i18n.setLocaleCookie(targetLocale);
      this.$router.push(this.switchLocalePath(targetLocale));
    }
  }
}
