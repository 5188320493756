const countryOptions = [
  { id: "ar", label: "Argentina" },
  { id: "br", label: "Brazil" },
  { id: "cl", label: "Chile" },
  { id: "co", label: "Colombia" },
  { id: "cr", label: "Costa Rica" },
  { id: "ec", label: "Ecuador" },
  { id: "mx", label: "Mexico" },
  { id: "pa", label: "Panama" },
  { id: "pe", label: "Peru" },
  { id: "xx", label: "Emptorland" },
];

export default countryOptions;
