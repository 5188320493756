
import { Vue, Component, Prop } from "vue-property-decorator";

import Button from "./Button.vue";
import Menu from "~/components/common/Menu.vue";

interface AuthValue {
  user: any;
  loggedIn: boolean;
  logout(): any;
}
@Component({
  components: { Button, Menu },
})
export default class Navbar extends Vue {
  @Prop() readonly auth!: AuthValue;
  @Prop({ default: true }) readonly localeSwitcher!: boolean;

  showMenu = false;

  mounted(): void {
    // Close dropdown menu when clicking outside
    document.body.addEventListener("mousedown", (event: MouseEvent): void => {
      if (event.target) {
        const element = event.target as HTMLElement;
        if (!(element.closest && element.closest("[data-dropdown]"))) {
          this.showMenu = false;
        }
      }
    });
  }

  get permissions(): any {
    return this.$store.state.permissions || {};
  }

  get canSwitchOwner(): boolean {
    return !!this.permissions.canSwitchOwner;
  }

  get currentOwner(): any {
    const curr = this.$store.state.currentOwner;
    return this.ownersList.find((owner: any) => owner.code === curr);
  }

  replaceAvatarByDefault(event: Event): void {
    const img = event.target as HTMLImageElement;
    img.src = "/img/avatar.png";
  }

  toggleSidebar(): void {
    this.$store.commit("toggleSidebar");
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  onSelectOwner(owner: any): void {
    const value = owner.code;
    this.$store.commit("setOwner", value);
    this.$store.commit("loadUsernames", null);
    this.$socket.changeOwner(value);
    localStorage.setItem("currentOwner", value);
    location.reload();
  }

  get ownersList(): any {
    return this.$store.state.owners.items.map((owner: any) => {
      if (owner.name) {
        return {
          code: owner.uid,
          label: `${owner.uid} (${owner.name})`,
        };
      } else {
        return {
          code: owner.uid,
          label: owner.uid,
        };
      }
    });
  }
}
