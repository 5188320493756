import { io } from "socket.io-client";

class Socket {
  constructor(auth, store, socket = null, owner = null) {
    this.auth = auth;
    this.store = store;
    this.socket = socket;
    this.owner = owner;
  }

  get connected() {
    return this.socket?.connected;
  }

  get currentOwner() {
    return this.owner;
  }

  changeOwner(owner) {
    this.owner = owner === undefined ? null : owner;
    this.socket.emit("change_owner", {
      owner: this.owner,
      token: this.auth.getToken("auth0"),
    });
  }

  connect(owner) {
    if (this.auth.loggedIn && !this.socket?.connected) {
      const token = this.auth.getToken("auth0");

      this.owner = owner === undefined ? null : owner;

      this.socket = io(process.env.WS_URL, {
        query: {
          owner: this.owner,
        },
        auth: {
          token,
        },
        transports: ["websocket"],
      });

      this.socket.on("addPerson", (message) => {
        this.store.dispatch("addPerson", message);
      });

      this.socket.on("updatePerson", (message) => {
        this.store.dispatch("updatePerson", message);
      });

      this.socket.on("setCredits", (credits) => {
        this.store.commit("setCredits", credits);
      });

      this.socket.on("error", (message) => {
        this.store.dispatch("loadWSErrors", message);
      });
    }
  }

  disconnect() {
    if (this.socket.connected) {
      this.socket.disconnect();
    }
  }
}

export default function ({ store, $auth }, inject) {
  const socket = new Socket($auth, store);
  if ($auth.loggedIn) {
    socket.connect();
  }
  inject("socket", socket);
}
