
import { Vue, Component } from "vue-property-decorator";

import Footer from "@/components/common/Footer.vue";
import Navbar from "@/components/common/Navbar.vue";
import Sidebar from "@/components/common/Sidebar.vue";

@Component({
  components: { Navbar, Sidebar, Footer },
  middleware: ["authenticated", "userdata"],
})
export default class DefaultLayout extends Vue {
  get country(): string {
    return this.$route.params.country;
  }

  get countries(): any {
    return this.$store.state.countries;
  }

  get currentOwner(): string {
    return this.$store.state.currentOwner;
  }
}
