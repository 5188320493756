export default function ({ store, redirect, $auth }) {
  const url = window.location.href;
  if (url.includes("error=unauthorized")) {
    store.dispatch("setMessage", "unauthorized");
  }

  // If in maintenance mode or the user is not authenticated
  // go back to the index
  if (process.env.MAINTENANCE_MODE || !$auth.loggedIn) {
    return redirect("/");
  }
}
