// formulate.config.js
import { en, es, pt } from "@braid/vue-formulate-i18n";
import { FormulateVSelectPlugin } from "~/components/form/FormulateVSelect";

export default {
  plugins: [en, es, pt, FormulateVSelectPlugin],
  locales: {
    en: {
      matches() {
        return "This value does not match the expected format";
      },
      required() {
        return "This field is required ";
      },
    },
    es: {
      matches() {
        return "Este valor no coincide con el formato esperado";
      },
      required() {
        return "Este campo es obligatorio";
      },
    },
  },
};
