
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class FormulateVSelect extends Vue {
  @Prop({ default: {} }) context!: any;

  reduce(x: any): boolean {
    return x && x.value;
  }

  onClose(): void {
    this.context.blurHandler();
  }
}

export function FormulateVSelectPlugin(formulateInstance: any): void {
  formulateInstance.extend({
    components: { FormulateVSelect },
    library: {
      "v-select": {
        classification: "search",
        component: "FormulateVSelect",
      },
    },
  });
}
