
import { Vue, Component } from "vue-property-decorator";
import Footer from "@/components/common/Footer.vue";
import Navbar from "@/components/common/Navbar.vue";

@Component({
  components: { Navbar, Footer },
})
export default class FormLayout extends Vue {
  mounted(): void {
    if (process.env.MAINTENANCE_MODE) {
      this.$nuxt.$router.replace({ path: "/" });
    }
  }
}
