var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.show)?_c('div',{staticClass:"block min-w-[12rem] absolute pointer-events-none z-10 py-2 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 group-hover:opacity-100 transition-opacity group-hover:delay-300 whitespace-normal leading-snug",class:{
    'left-0 -top-2 -translate-y-full': _vm.position === 'top',
    'left-0 -bottom-2 translate-y-full mt-4': _vm.position === 'bottom',
    'top-1/2 -translate-y-1/2 -right-2 translate-x-full':
      _vm.position === 'right',
  },attrs:{"role":"tooltip"}},[(!!_vm.$slots.header)?_c('div',{staticClass:"mb-1 pb-1 border-b border-b-gray-600 block whitespace-nowrap"},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_vm._t("body"),_vm._v(" "),_c('div',{staticClass:"border-transparent border-b-gray-800 border-x-[6px] border-b-[8px] absolute",class:{
      'bottom-[1px] translate-y-full rotate-180 left-1/4': _vm.position === 'top',
      '-top-[7px] rotate-0 left-1/4': _vm.position === 'bottom',
      '-left-[8px] -rotate-90 top-1/2 -translate-y-1/2': _vm.position === 'right',
    },attrs:{"role":"arrow"}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }