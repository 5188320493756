var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"appearance-none whitespace-nowrap border text-center cursor-pointer rounded px-3 py-2 transition-colors focus:outline-offset-1 disabled:cursor-default disabled:outline-none print:hidden flex-inline items-center justify-center space-x-1",class:{
    'border-slate-400 bg-zinc-100 text-slate-700 hover:bg-slate-100/90 hover:text-slate-600 focus:outline-emptor disabled:bg-slate-50 disabled:text-slate-400 shadow-md':
      _vm.variant === 'default' || !_vm.variant,
    'border-transparent bg-blue-500 text-white font-black hover:bg-blue-600 focus:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-200 shadow-md':
      _vm.variant === 'primary',
    'bg-red-500 text-white hover:bg-red-600 border-transparent disabled:bg-red-200 disabled:border-transparent disabled:text-gray-400 print:hidden':
      _vm.variant === 'danger',
    'bg-transparent border-transparent shadow-none text-indigo-500 hover:border-blue-100 hover:text-blue-500 hover:bg-gray-100 focus:text-blue-500 focus:bg-gray-200 focus:outline-blue-500 disabled:bg-gray-200 disabled:border-transparent disabled:text-gray-400':
      _vm.variant === 'text',
  },attrs:{"type":"button"},on:{"click":_vm.onClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }