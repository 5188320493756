
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
  get isOpen(): boolean {
    return this.$store.state.sidebarOpen;
  }

  get countries(): void {
    return this.$store.state.countries.countries;
  }

  get isOwnersEnabled(): boolean {
    return this.$store.state.owners.items.length > 0;
  }

  flagUrl(name: string): string {
    return `/img/${name.toLowerCase().replace(/ /g, "-")}.svg`;
  }

  closeSidebar(): void {
    this.$store.commit("closeSidebar");
  }
}
