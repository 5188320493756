/* eslint-disable */
// This is pendo's standard loading function.
// See https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo
function loadPendo(apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
}
/* eslint-enable */

function initializePendo(auth, owner, userLevel) {
  if (pendo && pendo.isReady && !pendo.isReady()) {
    pendo.initialize({
      visitor: {
        id: auth.user.email,
        full_name: auth.user.name,
        email: auth.user.email,
      },
      account: {
        id: owner,
        userLevel,
      },
    });
  } else if (pendo && pendo.isReady && pendo.isReady()) {
    if (pendo.visitorId !== auth.user.email) {
      pendo.identify({
        visitor: {
          id: auth.user.email,
          full_name: auth.user.name,
          email: auth.user.email,
        },
        account: {
          id: owner,
          userLevel,
        },
      });
    }
  } else {
    setTimeout(() => {
      initializePendo(auth, owner, userLevel);
    }, 500);
  }
}

export default async function ({ app, store, redirect, route }) {
  try {
    await store.dispatch("loadUserData");
  } catch (e) {
    redirect(app.localePath("/"));
  }

  if (store.state.userDataLoaded) {
    if (!route.name) {
      const country = store.state.countries.default;
      const targetLocale = store.state.locale;
      redirect(app.localePath(`/persons/${country}`, targetLocale));
    }

    const currentLocale = app.i18n.locale;
    const targetLocale = store.state.locale;
    if (currentLocale !== targetLocale) {
      app.i18n.locale = targetLocale;
      app.i18n.setLocaleCookie(targetLocale);
      return redirect(app.localePath(route.fullPath, targetLocale));
    }

    loadPendo(process.env.PENDO_API_ID);
    initializePendo(
      app.$auth,
      store.state.currentOwner,
      store.state.userLevel.level
    );
  }
}
