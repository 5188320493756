import { GetterTree } from "vuex";
import { RootState } from "./state";

const getters: GetterTree<RootState, RootState> = {
  isFreeTier(state): boolean {
    return state.userLevel.level === "LEVEL_FREE";
  },
};

export default getters;
