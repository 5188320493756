
import { Vue, Component } from "vue-property-decorator";
import Footer from "@/components/common/Footer.vue";

@Component({
  components: { Footer },
})
export default class MinimalLayout extends Vue {
  created(): void {
    if (this.$auth.loggedIn) {
      if (process.env.MAINTENANCE_MODE) {
        this.$auth.logout();
      }
    }
  }
}
