
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Button extends Vue {
  @Prop({ default: "default" }) variant!: string;

  onClick(e: any): void {
    this.$emit("click", e);
  }
}
