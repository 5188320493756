
import { Vue, Component } from "vue-property-decorator";
import { sortCountries } from "./utils";
import countryOptions from "~/components/common/countryOptions";

import Credits from "~/components/common/Credits.vue";
import ClientMetrics from "~/components/common/ClientMetrics.vue";
import SidebarCountryLink from "~/components/common/SidebarCountryLink.vue";

@Component({ components: { Credits, ClientMetrics, SidebarCountryLink } })
export default class Sidebar extends Vue {
  get isOpen(): boolean {
    return this.$store.state.sidebarOpen;
  }

  get canSeeMetrics(): boolean {
    return this.$store.state.permissions.canSwitchOwner ?? false;
  }

  get isFreeTier(): boolean {
    return this.$store.getters.isFreeTier;
  }

  get currentOwner(): string {
    return this.$store.state.currentOwner;
  }

  get countries(): Array<any> {
    const enabledCountries = Object.keys(
      sortCountries(this.$store.state.countries.countries)
    );
    let options = [...countryOptions];
    if (this.$store.state.currentOwner !== "emptor") {
      options = options.filter((country: any): any => {
        return country.id !== "xx";
      });
    }
    return options.map((country: any): any => {
      return {
        id: country.id,
        name: country.label,
        disabled: !enabledCountries.includes(country.id),
      };
    });
  }

  closeSidebar(): void {
    this.$store.commit("closeSidebar");
  }
}
