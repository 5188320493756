
import { Vue, Component } from "vue-property-decorator";
import Tooltip from "./Tooltip.vue";

@Component({
  components: { Tooltip },
})
export default class Credits extends Vue {
  get creditsAvailable(): number {
    return Math.max(0, this.$store.state.credits);
  }
}
