
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SidebarLink extends Vue {
  @Prop() readonly to!: string;
  @Prop() readonly name!: string;
  @Prop({ default: false }) readonly disabled!: boolean;

  flagUrl(name: string): string {
    return `/img/${name.toLowerCase().replace(/ /g, "-")}.svg`;
  }
}
