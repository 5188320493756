import {
  defaultPersons,
  defaultUser,
  initializePipelinesLoaded,
  initializePipelines,
  users,
  owners,
} from "./utils";

const state = (): any => ({
  countries: {
    default: "",
    countries: {},
  },
  currentOwner: "",
  currentCountry: "",
  flyoutOpenedDefault: false,
  generalMsg: "",

  metrics: {},
  uptime: 99,

  credits: undefined,
  userDataLoaded: false,

  stopWSLoading: false,
  wsErrors: [],
  persons: defaultPersons(),
  showAddPerson: false,
  personsAllSelected: false,
  personSelected: {},
  personsSelected: new Set(),

  personEdited: null,
  personFormPipelineId: "",
  personFormReports: [],
  personFormData: {},

  pipelinesLoaded: initializePipelinesLoaded(),
  pipelines: initializePipelines(),
  permissions: {
    canDeletePersons: false,
    canManageUsers: false,
    canReloadPersons: false,
    canSwitchOwner: false,
    canEditPersons: true,
    canUseAdvanced: false,
    canCreatePipeline: false,
    canUpdatePipeline: false,
    canDeletePipeline: false,
    canCreatePreCandidate: false,
  },
  isFlyoutOpen: false,
  shouldOpenUserFlyout: false,
  shouldOpenOwnerFlyout: false,
  showPersonFilter: false,
  sidebarOpen: false,

  user: defaultUser(),
  users: users(),
  usersEdited: new Set(),
  usersSelected: new Set(),
  showUserFilterForm: false,
  allowedCountries: [],
  availableRoles: { roles: [] },
  usernames: null,

  owners: owners(),
  ownerEdited: null,
  countrySelected: {},
  userLevel: { level: "", roles_allowed: [] },

  locale: null,
});

export default state;

export type RootState = ReturnType<typeof state>;
