import { GetterTree } from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import state, { RootState } from "./state";

export const getters: GetterTree<RootState, RootState> = {
  name: (state): string => state.user.firstName + " " + state.user.lastName,
};

export const rootStoreConfig = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
