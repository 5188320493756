import type { Owner, Pipeline, User } from "@/types/app";

const supportedCountries = [
  "ar",
  "br",
  "cl",
  "co",
  "cr",
  "ec",
  "mx",
  "pa",
  "pe",
  "xx",
];

export function defaultPersons(): any {
  return {
    items: [],
    page: 1,
    order: "-id",
    numpages: 1,
    loading: false,
    filters: [],
    searchTerms: [],
  };
}

export function defaultUser(): any {
  return {
    countries: [],
    updatedAt: undefined,
    status: "",
    userName: "",
    firstName: "Emptor",
    lastName: "",
    hasAPI: false,
    owner: "",
  };
}

type Users = {
  items: User[];
  page: number;
  order: string;
  numpages: number;
  numtotal: number;
  loading: boolean;
  filters: string[];
  searchTerms: string[];
};

export function users(): Users {
  return {
    items: [],
    page: 1,
    order: "username",
    numpages: 1,
    numtotal: 1,
    loading: false,
    filters: [],
    searchTerms: [],
  };
}

type Owners = {
  items: Owner[];
  order: string;
  loading: boolean;
};

export function owners(): Owners {
  return {
    items: [],
    order: "uid",
    loading: false,
  };
}

export function initializePipelinesLoaded(): Record<string, boolean> {
  let pipelinesLoaded: Record<string, boolean> = {};

  supportedCountries.forEach((country: string): void => {
    pipelinesLoaded[country] = false;
  });

  return pipelinesLoaded;
}

export function initializePipelines(): Record<string, Pipeline[]> {
  let pipelines: Record<string, Pipeline[]> = {};

  supportedCountries.forEach((country: string): void => {
    pipelines[country] = [];
  });

  return pipelines;
}

export function normalizePipeline(pip: any): Pipeline {
  return {
    id: pip.pipeline_id,
    countryCode: pip.country_code,
    displayName: pip.alias || pip.name,
    alias: pip.alias || "",
    name: pip.name,
    preCandidate: !!pip.precandidate,
    description: pip.description || "",
    reportSettings: pip.report_settings,
    reportsEnabled: pip.reports_enabled,
    createdAt: new Date(pip.created_at),
    updatedAt: new Date(pip.updated_at),
  };
}

export function buildPersonsFiltersQuery(state: any): string {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let url =
    `page=${state.persons.page || 1}` +
    `&order=${state.persons.order || ""}` +
    `&timezone=${timezone}`;

  function isPersonId(term: string): boolean {
    const re = /[\w]{8}(-[\w]{4}){3}-[\w]{12}/;
    if (term && re.test(term)) {
      return true;
    }
    return false;
  }

  const uids: any[] = [];
  const queries: any[] = [];

  function addList(term: string): void {
    if (isPersonId(term)) {
      uids.push(term);
    } else {
      queries.push(term);
    }
  }

  state.persons.searchTerms.forEach((element: any): void =>
    addList(element.trim())
  );

  if (uids.length > 0) {
    url += `&personUid=${uids.toString()}`;
  }
  if (queries.length > 0) {
    url += `&q=${queries.toString()}`;
  }
  state.persons.filters.forEach((f: any): void => {
    url += `&f=${f.filterBy},${f.cmp},${f.value}`;
  });
  return url;
}

export function buildUsersFiltersQuery(state: any): string {
  let url = `page=${state.users.page || 1}&order=${state.users.order || ""}`;

  const userNames: any[] = [];
  state.users.searchTerms.forEach((element: any): void => {
    userNames.push(element.trim());
  });
  if (userNames.length > 0) {
    url += `&userNames=${encodeURIComponent(userNames.toString())}`;
  }
  state.users.filters.forEach((f: any): void => {
    url += `&f=${f.filterBy},${f.cmp},${f.value}`;
  });
  return url;
}
